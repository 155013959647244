import React from 'react'
import Fade from 'react-reveal/Fade'
import AboutMobile from './AboutMobile'
import ContactMobile from './ContactMobile'
import HelloMobile from './HelloMobile'

function AboutMenuMobile({ about }) {
  return (
    <div style={{ width: 'calc(100vw - 64px)', margin: '0 auto' }}>
      <section id="aboutMenuMobile">
        <div id="lineSpacer" />

        <div
          id="spacer"
          style={{
            height: '41px',
            marginLeft: '25%',
          }}
        />

        <Fade duration={1000} delay={100} distance="30px">
          <div id="helloMobileContainer">
            <div id="helloMobileText">
              <HelloMobile data={about.Hello} />
            </div>
            <div id="helloMobileShadow" />
          </div>
        </Fade>

        <div
          id="spacer"
          style={{
            height: '32px',
            marginLeft: '25%',
          }}
        />

        <Fade duration={1000} delay={300} distance="30px">
          <div id="aboutMobileContainer">
            <div id="aboutMobileText">
              <AboutMobile data={about.About} />
            </div>
            <div id="aboutMobileShadow" />
          </div>
        </Fade>

        <div
          id="spacer"
          style={{
            height: '32px',
            marginLeft: '25%',
          }}
        />

        <Fade duration={1000} delay={500} distance="30px">
          <div id="contactMobileContainer">
            <div id="contactMobileText">
              <ContactMobile data={about.Contact} />
            </div>
            <div id="contactMobileShadow" />
          </div>
        </Fade>
        <div style={{ height: '40px' }} />
      </section>
    </div>
  )
}

export default AboutMenuMobile
