import React, { useRef } from 'react'
import SquareImage from './SquareImage'

const directions = { 0: 'top', 1: 'right', 2: 'bottom', 3: 'left' }
const classNames = ['in', 'out']
  .map((p) => Object.values(directions).map((d) => `${p}-${d}`))
  .reduce((a, b) => a.concat(b))

function Square({ squareData, viewing, onClick }) {
  const { id, coordinates, title, backgroundColor, hoverImage } = squareData
  const [x, y] = coordinates
  const nodeRef = useRef(null)

  const getDirectionKey = (ev, node) => {
    const { width, height, top, left } = node.getBoundingClientRect()
    const l = ev.pageX - (left + window.pageXOffset)
    const t = ev.pageY - (top + window.pageYOffset)
    const x = l - (width / 2) * (width > height ? height / width : 1)
    const y = t - (height / 2) * (height > width ? width / height : 1)
    return Math.round(Math.atan2(y, x) / 1.57079633 + 5) % 4
  }

  function updateNode(direction, prefix) {
    nodeRef.current.classList.remove(...classNames)
    nodeRef.current.classList.add(`${prefix}-${direction}`)
  }

  function onMouseEnter(e) {
    const directionKey = getDirectionKey(e, e.target)
    const direction = directions[directionKey]
    updateNode(direction, 'in')
  }

  function onMouseLeave(e) {
    const directionKey = getDirectionKey(e, e.target)
    const direction = directions[directionKey]
    updateNode(direction, 'out')
  }

  return (
    <div
      key={id}
      id="node"
      className="square"
      style={{
        gridRowStart: `${x}`,
        gridRowEnd: `${x + 1}`,
        gridColumnStart: `${y}`,
        gridColumnEnd: `${y + 1}`,
        backgroundColor: window.getComputedStyle(document.documentElement).getPropertyValue(backgroundColor),
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={() => onClick(title)}
    >
      <li ref={nodeRef} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <div
          className="normal"
          style={{
            gridRowStart: `${x}`,
            gridRowEnd: `${x + 1}`,
            gridColumnStart: `${y}`,
            gridColumnEnd: `${y + 1}`,
            backgroundColor: window.getComputedStyle(document.documentElement).getPropertyValue(backgroundColor),
          }}
        />
        <div className="info">
          <div className={viewing !== title ? 'image-wrapper' : ''}>
            <SquareImage filename={hoverImage} alt={title} />
          </div>
        </div>
      </li>
    </div>
  )
}

export default Square
