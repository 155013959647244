import React, { useEffect } from 'react'
import Fade from 'react-reveal/Fade'
import GenericImage from '../../GenericImage/GenericImage'
import DocumentWrapper from './common/DocumentWrapper'
import TitleDetailsBlurb from './common/TitleDetailsBlurb'
import { scroller, Element } from 'react-scroll'
import ScrollSection from './common/ScrollSection'
import { scrollToViewingOptions } from '../../App'
import Scroller from './common/Scroller'

function Stationeries(props) {
  const { data, isMobile, setBelowTop } = props

  useEffect(() => {
    scroller.scrollTo('ref1', scrollToViewingOptions)
  }, [])

  return (
    <>
      <Scroller setBelowTop={setBelowTop} />
      <DocumentWrapper isMobile={isMobile}>
        <ScrollSection id={'ref1'}>
          <TitleDetailsBlurb
            title={data.title}
            year={data.year}
            categories={data.categories}
            blurb={data.blurb}
            isMobile={isMobile}
          />
        </ScrollSection>
        <ScrollSection id={'ref2'}>
          <div id={isMobile ? 'sketchbookColumnsMobile' : 'sketchbookColumns'}>
            <div className={'sketchbookColumn'}>
              <GenericImage filename={data.imageOne} alt={''} />
              <GenericImage filename={data.imageTwo} alt={''} />
              <GenericImage filename={data.imageThree} alt={''} />
              <GenericImage filename={data.imageFour} alt={''} />
            </div>
            <div className={'sketchbookColumn'}>
              <GenericImage filename={data.imageFive} alt={''} />
              <GenericImage filename={data.imageSix} alt={''} />
              <GenericImage filename={data.imageSeven} alt={''} />
              <GenericImage filename={data.imageEight} alt={''} />
            </div>
          </div>
        </ScrollSection>
        <div style={{ height: '40px' }} />
      </DocumentWrapper>
    </>
  )
}

export default Stationeries

{
  /* <section id="stationeries">
  <div className="sectionTitle">{data.sectionOneTitle}</div>
  <div style={{ height: '48px' }} />
  <div className={'hoverContainer'}>{mice}</div>
  <div style={{ height: '207px' }} />
  <div className="sectionTitle">{data.sectionTwoTitle}</div>
  <div style={{ height: '48px' }} />
  <div className={'hoverContainer'}>{cats}</div>
  <div style={{ height: '400px' }} />
</section> */
}

// const [mouseSort, setMouseSort] = useState(['four', 'three', 'two', 'one'])

//   function moveToFirst(name) {
//     let mice = [...mouseSort].filter((prev) => prev !== name)
//     mice.push(name)
//     setMouseSort(mice)
//   }

//   function onMouseEnter(id, name) {
//     moveToFirst(name)
//     const item = document.querySelector(id)
//     setTimeout(() => {
//       item.classList.add('hoveredStationeryImage')
//     }, 100)
//   }

//   function onMouseLeave(id) {
//     const item = document.querySelector(id)
//     item.classList.remove('hoveredStationeryImage')
//   }

//   const mouseOne = (
//     <div
//       key={'one'}
//       className={'stationeryImage'}
//       id={'stationeryImageOne'}
//       onMouseEnter={() => onMouseEnter(`#stationeryImageOne`, 'one')}
//       onMouseLeave={() => onMouseLeave(`#stationeryImageOne`)}
//     >
//       <GenericImage filename={data.imageOne} alt={'mouse one'} />
//     </div>
//   )

//   const mouseTwo = (
//     <div
//       key={'two'}
//       className={'stationeryImage'}
//       id={'stationeryImageTwo'}
//       onMouseEnter={() => onMouseEnter(`#stationeryImageTwo`, 'two')}
//       onMouseLeave={() => onMouseLeave(`#stationeryImageTwo`)}
//     >
//       <GenericImage filename={data.imageTwo} alt={'mouse two'} />
//     </div>
//   )

//   const mouseThree = (
//     <div
//       key={'three'}
//       className={'stationeryImage'}
//       id={'stationeryImageThree'}
//       onMouseEnter={() => onMouseEnter(`#stationeryImageThree`, 'three')}
//       onMouseLeave={() => onMouseLeave(`#stationeryImageThree`)}
//     >
//       <GenericImage filename={data.imageThree} alt={'mouse three'} />
//     </div>
//   )

//   const mouseFour = (
//     <div
//       key={'four'}
//       className={'stationeryImage'}
//       id={'stationeryImageFour'}
//       onMouseEnter={() => onMouseEnter(`#stationeryImageFour`, 'four')}
//       onMouseLeave={() => onMouseLeave(`#stationeryImageFour`)}
//     >
//       <GenericImage filename={data.imageFour} alt={'mouse four'} />
//     </div>
//   )

//   const nameToMouse = {
//     one: mouseOne,
//     two: mouseTwo,
//     three: mouseThree,
//     four: mouseFour,
//   }

//   const mice = mouseSort.map((name) => nameToMouse[name])

//   const [catSort, setCatSort] = useState(['eight', 'seven', 'six', 'five'])

//   function moveCatToFirst(name) {
//     let cats = [...catSort].filter((prev) => prev !== name)
//     cats.push(name)
//     setCatSort(cats)
//   }

//   function onCatEnter(id, name) {
//     moveCatToFirst(name)
//     const item = document.querySelector(id)
//     setTimeout(() => {
//       item.classList.add('hoveredStationeryImage')
//     }, 100)
//   }

//   function onCatLeave(id) {
//     const item = document.querySelector(id)
//     item.classList.remove('hoveredStationeryImage')
//   }

//   const catOne = (
//     <div
//       key={'five'}
//       className={'stationeryImage'}
//       id={'stationeryImageFive'}
//       onMouseEnter={() => onCatEnter(`#stationeryImageFive`, 'five')}
//       onMouseLeave={() => onCatLeave(`#stationeryImageFive`)}
//     >
//       <GenericImage filename={data.imageFive} alt={'cat one'} />
//     </div>
//   )

//   const catTwo = (
//     <div
//       key={'six'}
//       className={'stationeryImage'}
//       id={'stationeryImageSix'}
//       onMouseEnter={() => onCatEnter(`#stationeryImageSix`, 'six')}
//       onMouseLeave={() => onCatLeave(`#stationeryImageSix`)}
//     >
//       <GenericImage filename={data.imageSix} alt={'cat two'} />
//     </div>
//   )

//   const catThree = (
//     <div
//       key={'seven'}
//       className={'stationeryImage'}
//       id={'stationeryImageSeven'}
//       onMouseEnter={() => onCatEnter(`#stationeryImageSeven`, 'seven')}
//       onMouseLeave={() => onCatLeave(`#stationeryImageSeven`)}
//     >
//       <GenericImage filename={data.imageSeven} alt={'cat three'} />
//     </div>
//   )

//   const catFour = (
//     <div
//       key={'eight'}
//       className={'stationeryImage'}
//       id={'stationeryImageEight'}
//       onMouseEnter={() => onCatEnter(`#stationeryImageEight`, 'eight')}
//       onMouseLeave={() => onCatLeave(`#stationeryImageEight`)}
//     >
//       <GenericImage filename={data.imageEight} alt={'cat four'} />
//     </div>
//   )

//   const nameToCat = {
//     five: catOne,
//     six: catTwo,
//     seven: catThree,
//     eight: catFour,
//   }

//   const cats = catSort.map((name) => nameToCat[name])
