import React from 'react'
import SquareMobile from './SquareMobile'

function GridMobile({ squaresData, viewing, onClick }) {
  const squares = squaresData.map((squareData) => (
    <SquareMobile key={squareData.id} viewing={viewing} squareData={squareData} onClick={onClick} />
  ))

  return (
    <>
      <div id="workLineSpacer" />
      <section id="gridMobile">{squares}</section>
    </>
  )
}

export default GridMobile
