import React, { useState } from 'react'
import GenericSVG from '../GenericImage/GenericSVG'
import DoubleZero from './Projects/DoubleZero'
import Lister from './Projects/Lister'
import PaperIslands from './Projects/PaperIslands'
import Stationeries from './Projects/Stationeries'

import { Link } from 'react-scroll'
import Sketchbook from './Projects/Sketchbook'
import SargassoDesign from './Projects/SargassoDesign'
import KAF from './Projects/KAF'
import UpperForm from './Projects/UpperForm'
import PKs from './Projects/PKs'
import Saab from './Projects/Saab'

function Viewing(props) {
  const { viewing, onReturnToTop, projects, isMobile } = props
  const [belowTop, setBelowTop] = useState(false)

  const data = projects[viewing]

  return (
    <section id={isMobile ? 'viewingMobile' : 'viewing'}>
      {isMobile ? (
        <div className={belowTop ? 'fixedClose' : 'absoluteClose'}>
          <div className="closeIcon" onClick={onReturnToTop}>
            <GenericSVG filename={'close-project.svg'} alt={'close'} />
          </div>
        </div>
      ) : (
        <div className={belowTop ? 'fixedClose' : 'absoluteClose'}>
          <div className="closeIcon" onClick={onReturnToTop}>
            <GenericSVG filename={'close-project.svg'} alt={'close'} />
          </div>
        </div>
      )}
      {viewing === 'Sketchbook' && (
        <Sketchbook data={data} belowTop={belowTop} setBelowTop={setBelowTop} isMobile={isMobile} />
      )}
      {viewing === 'Lister' && <Lister data={data} belowTop={belowTop} setBelowTop={setBelowTop} isMobile={isMobile} />}
      {viewing === 'Sargasso Design' && (
        <SargassoDesign data={data} belowTop={belowTop} setBelowTop={setBelowTop} isMobile={isMobile} />
      )}
      {viewing === 'Stationeries' && (
        <Stationeries data={data} belowTop={belowTop} setBelowTop={setBelowTop} isMobile={isMobile} />
      )}
      {viewing === 'Line at KAF' && (
        <KAF data={data} belowTop={belowTop} setBelowTop={setBelowTop} isMobile={isMobile} />
      )}
      {viewing === 'Upper Form' && (
        <UpperForm data={data} belowTop={belowTop} setBelowTop={setBelowTop} isMobile={isMobile} />
      )}
      {viewing === `PK's Four Brothers Farm` && (
        <PKs data={data} belowTop={belowTop} setBelowTop={setBelowTop} isMobile={isMobile} />
      )}
      {viewing === 'SAAB' && <Saab data={data} belowTop={belowTop} setBelowTop={setBelowTop} isMobile={isMobile} />}
      {viewing === 'Double Zero' && (
        <DoubleZero data={data} belowTop={belowTop} setBelowTop={setBelowTop} isMobile={isMobile} />
      )}
      {viewing === 'Paper Islands' && (
        <PaperIslands data={data} belowTop={belowTop} setBelowTop={setBelowTop} isMobile={isMobile} />
      )}
    </section>
  )
}

export default Viewing
