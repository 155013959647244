import React, { useEffect, useRef, useState } from 'react'
import cx from 'classnames'

export const CursorContext = React.createContext({ onCursor: (cursorType: any) => {} })

const SUPPORTED_CURSORS = [false, 'pointer', 'right', 'left']

const CursorProvider = ({ children }) => {
  const [cursor, setCursor] = useState(false)
  const cursorRef = useRef(null)

  const onMouseMove = (e) => {
    cursorRef.current.style.left = `${e.pageX}px`
    cursorRef.current.style.top = `${e.pageY}px`
  }

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove)

    return () => {
      document.removeEventListener('mousemove', onMouseMove)
    }
  })

  function onCursor(cursorType) {
    cursorType = (SUPPORTED_CURSORS.includes(cursorType) && cursorType) || false
    setCursor(cursorType)
  }

  return (
    <CursorContext.Provider value={{ onCursor }}>
      <ins
        ref={cursorRef}
        className={cx('movable', {
          active: !!cursor,
          [`cursor-${cursor}`]: !!cursor,
        })}
      />
      {children}
    </CursorContext.Provider>
  )
}

export default CursorProvider
