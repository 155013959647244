import React from 'react'
import GenericSVG from '../../GenericImage/GenericSVG'

function AboutMobile({ data }) {
  return (
    <section id="aboutMobile">
      <div id="paragraphOne">{data.paragraphOne}</div>
    </section>
  )
}

export default AboutMobile
