import React, { useEffect } from 'react'
import DocumentWrapper from './common/DocumentWrapper'
import TitleDetailsBlurb from './common/TitleDetailsBlurb'
import { scroller } from 'react-scroll'
import ScrollSection from './common/ScrollSection'
import Scroller from './common/Scroller'
import GenericImage from '../../GenericImage/GenericImage'
import { scrollToViewingOptions } from '../../App'
import GenericGIF from '../../GenericImage/GenericGIF'

function KAF(props) {
  const { data, belowTop, setBelowTop, isMobile } = props

  useEffect(() => {
    scroller.scrollTo('ref1', scrollToViewingOptions)
  }, [])

  return (
    <>
      <Scroller setBelowTop={setBelowTop} />
      <DocumentWrapper isMobile={isMobile}>
        <ScrollSection id={'ref1'}>
          <TitleDetailsBlurb
            title={data.title}
            year={data.year}
            categories={data.categories}
            blurb={data.blurb}
            isMobile={isMobile}
          >
            <>
              <div style={{ height: '49px' }} />
              <div id={isMobile ? 'detailsMobile' : 'details'}>
                <div id={'detailsDescription'}>
                  {Object.keys(data.details).map((item) => (
                    <div key={item}>{item}:</div>
                  ))}
                </div>
                <div id="detailsContributors">
                  {Object.values(data.details).map((item, index) => (
                    <div key={`${item}-${index}`}>{item}</div>
                  ))}
                </div>
              </div>
            </>
          </TitleDetailsBlurb>
        </ScrollSection>

        <section id={isMobile ? 'kafMobile' : 'kaf'}>
          <ScrollSection id={'ref2'}>
            {/* <div style={{ height: isMobile ? '40px' : '126px' }} /> */}
            <div id="topLogo">
              <GenericImage filename={data.imageOne} alt={'kaf one'} />
            </div>
            <div style={{ height: isMobile ? '40px' : '126px' }} />
            <div id={isMobile ? 'kafDescriptionMobile' : 'kafDescription'}>{data.description}</div>
          </ScrollSection>

          <ScrollSection id={'ref3'}>
            <div style={{ height: isMobile ? '40px' : '126px' }} />
            <div className={isMobile ? 'kafHeaderMobile' : 'kafHeader'}>{data.headerOne}</div>
            <div className={isMobile ? 'kafSubHeaderMobile' : 'kafSubHeader'}>{data.subHeaderOne}</div>
            <div className={isMobile ? 'kafSubHeaderTextMobile' : 'kafSubHeaderText'}>{data.subHeaderOneText}</div>
            <div style={{ height: isMobile ? '20px' : '80px' }} />

            <div className={isMobile ? 'kafRowMobile' : 'kafRow'}>
              <div className="rowItem">
                <div className="rowItemImage">
                  <div id="color">
                    <GenericImage filename={data.imageTwo} alt={'kaf two'} />
                  </div>
                </div>
                <div className="rowItemText">{data.greenText}</div>
              </div>
              <div className="rowItem">
                <div className="rowItemImage">
                  <div id="color">
                    <GenericImage filename={data.imageThree} alt={'kaf three'} />
                  </div>
                </div>
                <div className="rowItemText">{data.yellowText}</div>
              </div>
              <div className="rowItem">
                <div className="rowItemImage">
                  <div id="color">
                    <GenericImage filename={data.imageFour} alt={'kaf four'} />
                  </div>
                </div>
                <div className="rowItemText">{data.redText}</div>
              </div>
            </div>
          </ScrollSection>

          <ScrollSection id={'ref4'}>
            <div style={{ height: isMobile ? '40px' : '117px' }} />
            <div className={isMobile ? 'kafSubHeaderMobile' : 'kafSubHeader'}>{data.subHeaderTwo}</div>
            <div className={isMobile ? 'kafSubHeaderTextMobile' : 'kafSubHeaderText'}>{data.subHeaderTwoText}</div>
            <div style={{ height: isMobile ? '20px' : '80px' }} />

            <div className={isMobile ? 'kafRowMobile' : 'kafRow'}>
              <div className="rowItem">
                <div className="rowItemImage">
                  <div id="cup">
                    <GenericImage filename={data.imageFive} alt={'kaf five'} />
                  </div>
                </div>
                <div className="rowItemText">{data.cupText}</div>
              </div>
              <div className="rowItem">
                <div className="rowItemImage">
                  <div id="door">
                    <GenericImage filename={data.imageSix} alt={'kaf six'} />
                  </div>
                </div>
                <div className="rowItemText">{data.doorText}</div>
              </div>
              <div className="rowItem">
                <div className="rowItemImage">
                  <div id="window">
                    <GenericImage filename={data.imageSeven} alt={'kaf seven'} />
                  </div>
                </div>
                <div className="rowItemText">{data.windowText}</div>
              </div>
            </div>
          </ScrollSection>

          <ScrollSection id={'ref5'}>
            <div style={{ height: isMobile ? '40px' : '117px' }} />
            <div className={isMobile ? 'kafSubHeaderMobile' : 'kafSubHeader'}>{data.subHeaderThree}</div>
            <div className={isMobile ? 'kafSubHeaderTextMobile' : 'kafSubHeaderText'}>{data.subHeaderThreeText}</div>
            <div style={{ height: '80px' }} />
            <div className="kafWideImage">
              <GenericGIF filename={data.imageEight} alt={'kaf eight'} />
            </div>
          </ScrollSection>

          <ScrollSection id={'ref6'}>
            <div style={{ height: isMobile ? '40px' : '117px' }} />
            <div className={isMobile ? 'kafSubHeaderMobile' : 'kafSubHeader'}>{data.subHeaderFour}</div>
            <div className={isMobile ? 'kafSubHeaderTextMobile' : 'kafSubHeaderText'}>{data.subHeaderFourText}</div>
            <div style={{ height: isMobile ? '20px' : '80px' }} />
            <div className="kafWideImage">
              <GenericImage filename={data.imageNine} alt={'kaf nine'} />
            </div>
          </ScrollSection>

          <ScrollSection id={'ref7'}>
            <div style={{ height: isMobile ? '40px' : '117px' }} />
            <div className={isMobile ? 'kafSubHeaderMobile' : 'kafSubHeader'}>{data.subHeaderFive}</div>
            <div className={isMobile ? 'kafSubHeaderTextMobile' : 'kafSubHeaderText'}>{data.subHeaderFiveText}</div>
            <div style={{ height: isMobile ? '20px' : '80px' }} />
            <div className="kafWideImage">
              <GenericImage filename={data.imageTen} alt={'kaf ten'} />
            </div>
          </ScrollSection>

          <ScrollSection id={'ref8'}>
            <div style={{ height: isMobile ? '40px' : '117px' }} />
            <div className={isMobile ? 'kafSubHeaderMobile' : 'kafSubHeader'}>{data.subHeaderSix}</div>
            <div className={isMobile ? 'kafSubHeaderTextMobile' : 'kafSubHeaderText'}>{data.subHeaderSixText}</div>
            <div style={{ height: isMobile ? '20px' : '80px' }} />
            <div className="kafWideImage">
              <GenericGIF filename={data.imageEleven} alt={'kaf eleven'} />
            </div>
          </ScrollSection>

          <ScrollSection id={'ref9'}>
            <div style={{ height: isMobile ? '40px' : '117px' }} />
            <div className={isMobile ? 'kafHeaderMobile' : 'kafHeader'}>{data.headerTwo}</div>
            <div style={{ height: isMobile ? '20px' : '80px' }} />
            <div className="kafWideImage">
              <GenericImage filename={data.imageTwelve} alt={'kaf twelve'} />
            </div>
            <div style={{ height: isMobile ? '40px' : '117px' }} />
          </ScrollSection>
        </section>
      </DocumentWrapper>
    </>
  )
}

export default KAF
