import React, { useState } from 'react'
import { Link } from 'react-scroll'

const refs = ['ref0', 'ref1', 'ref2', 'ref3', 'ref4', 'ref5', 'ref6', 'ref7', 'ref8', 'ref9']

function Scroller(props) {
  const { belowTop, setBelowTop } = props
  const [atTop, setAtTop] = useState(true)
  const offsets = {
    ref0: 0,
  }

  function onSetActive(to) {
    if (to === refs[0]) {
      setAtTop(true)
    } else if (atTop) {
      setAtTop(false)
    }

    if (!refs.slice(1).includes(to)) {
      setBelowTop(false)
    } else {
      setBelowTop(true)
    }
  }

  return (
    <div style={{ display: 'none' }}>
      <div className={atTop ? 'hiddenScroller' : 'visibleScroller'}>
        <div id="scroller">
          {refs?.map((ref) => (
            <Link
              key={ref}
              onSetActive={onSetActive}
              activeClass={'activeScrollDot'}
              to={ref}
              spy={true}
              smooth={true}
              offset={offsets[ref] ?? 0}
            ></Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Scroller
