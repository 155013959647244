import React from 'react'
import Square from './Square'

function Grid({ squaresData, viewing, onClick }) {
  const squares = squaresData.map((squareData) => (
    <Square key={squareData.id} viewing={viewing} squareData={squareData} onClick={onClick} />
  ))

  return (
    <section id="grid" style={{ borderBottom: viewing ? '8px solid transparent' : '8px solid transparent' }}>
      {squares}
    </section>
  )
}

export default Grid
