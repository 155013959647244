import React from 'react'
import GenericSVG from '../../../GenericImage/GenericSVG'

function Website(props) {
  const { url, isMobile } = props

  return (
    <section id={isMobile ? 'websiteMobile' : 'website'}>
      <a href={url} target="_blank">
        <div className="websiteWrapper">
          <div className="websiteIcon">
            <GenericSVG filename="website.svg" alt="website" />
          </div>
          <div className="websiteText">visit website</div>
        </div>
      </a>
    </section>
  )
}

export default Website
