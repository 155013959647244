import React, { useEffect } from 'react'
import Fade from 'react-reveal/Fade'
import LargeImage from '../../GenericImage/LargeImage'
import DocumentWrapper from './common/DocumentWrapper'
import TitleDetailsBlurb from './common/TitleDetailsBlurb'
import { scroller, Element } from 'react-scroll'
import ScrollSection from './common/ScrollSection'
import { scrollToViewingOptions } from '../../App'
import Scroller from './common/Scroller'

function DoubleZero(props) {
  const { data, isMobile, setBelowTop } = props

  useEffect(() => {
    scroller.scrollTo('ref1', scrollToViewingOptions)
  }, [])

  return (
    <>
      <DocumentWrapper isMobile={isMobile}>
        <Scroller setBelowTop={setBelowTop} />
        <ScrollSection id={'ref1'}>
          <TitleDetailsBlurb
            title={data.title}
            year={data.year}
            categories={data.categories}
            blurb={data.blurb}
            isMobile={isMobile}
          />
        </ScrollSection>
        <section id={isMobile ? 'double-zero-mobile' : 'double-zero'}>
          <div id={isMobile ? 'double-zero-image-one-mobile' : 'double-zero-image-one'}>
            <LargeImage filename={data.imageOne} alt={'list one'} />
          </div>
          <div style={{ height: isMobile ? '20px' : '60px' }} />
          <div id={isMobile ? 'double-zero-image-two-mobile' : 'double-zero-image-two'}>
            <LargeImage filename={data.imageTwo} alt={'list two'} />
          </div>
          <div id={isMobile ? '' : 'pinkWrapper'}>
            <div style={{ height: isMobile ? '40px' : '154px' }} />
            <div id={isMobile ? 'double-zero-image-one-mobile' : 'double-zero-image-three'}>
              <LargeImage filename={data.imageThree} alt={'list three'} />
            </div>
            <div style={{ height: isMobile ? '20px' : '40px' }} />
            <div id={isMobile ? 'double-zero-image-one-mobile' : 'double-zero-image-three'}>
              <LargeImage filename={data.imageFour} alt={'list four'} />
            </div>
            <div style={{ height: isMobile ? '40px' : '154px' }} />
          </div>
          <div style={{ height: isMobile ? '40px' : '214px' }} />
          <div id="double-zero-image-five">
            <LargeImage filename={data.imageFive} alt={'list five'} />
          </div>
          <div style={{ height: isMobile ? '80px' : '202px' }} />
        </section>
      </DocumentWrapper>
    </>
  )
}

export default DoubleZero
