import React from 'react'

function TitleDetailsBlurb(props) {
  const { title, year, categories, blurb, children = <></>, isMobile } = props

  const categoryList = categories.map((c, i) => {
    let text = `${c}`
    let spacer = false
    if (i < categories.length - 1) {
      spacer = true
    }
    return (
      <div className={'category'} key={c}>
        {text}
        {isMobile && spacer ? <span className={'categorySpacer'}>|</span> : <></>}
      </div>
    )
  })
  return (
    <>
      <div style={{ height: isMobile ? '41px' : '135px' }} />
      <div className={isMobile ? 'titleDetailsBlurbWrapperMobile' : 'titleDetailsBlurbWrapper'} id="ref1">
        <div className={'title'}>{title}</div>
        <div className={'detailsAndBlurb'}>
          <div className={'details'}>
            <div className={'year'}>{year}</div>
            <div className={'categories'}>{categoryList}</div>
          </div>
          <div className={'blurb'}>
            {blurb}
            {children && children}
          </div>
        </div>
      </div>
      <div style={{ height: isMobile ? '20px' : '80px' }} />
    </>
  )
}

export default TitleDetailsBlurb
