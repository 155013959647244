import React from 'react'
import SquareImage from '../Grid/SquareImage'

function SquareMobile({ squareData, viewing, onClick }) {
  const { id, coordinatesMobile, title, backgroundColorMobile, hoverImage } = squareData
  const [x, y] = coordinatesMobile

  return (
    <div
      key={id}
      className="squareMobile"
      style={{
        gridRowStart: `${x}`,
        gridRowEnd: `${x + 1}`,
        gridColumnStart: `${y}`,
        gridColumnEnd: `${y + 1}`,
        backgroundColor: window.getComputedStyle(document.documentElement).getPropertyValue(backgroundColorMobile),
      }}
      onClick={() => onClick(title)}
    >
      {title}
      {/* <div className={viewing !== title ? 'image-wrapper' : ''}> */}
      {/* <SquareImage filename={hoverImage} alt={title} /> */}
      {/* </div> */}
    </div>
  )
}

export default SquareMobile
