import React from 'react'
import GenericSVG from '../GenericImage/GenericSVG'

function FooterMobile(props) {
  const { viewing } = props
  return (
    <section id={viewing ? 'footerMobileViewing' : 'footerMobile'}>
      <div id="footerMobileContent">
        <div id="footerImage">
          <a href="https://www.linkedin.com/in/anne-muller" target="_blank">
            <GenericSVG filename="footer-linked-in" alt="linked-in" />
          </a>
        </div>
        <div id="footerImage">
          <a href="mailto:annechancemuller@icloud.com" target="_blank">
            <GenericSVG filename="footer-email" alt="linked-in" />
          </a>
        </div>
        <div id="footerImage">
          <a href="http://instagram.com/upperform" target="_blank">
            <GenericSVG filename="footer-instagram" alt="linked-in" />
          </a>
        </div>
      </div>
    </section>
  )
}

export default FooterMobile
