import React, { useContext, useEffect, useState } from 'react'
import Hello from './Hello'
import About from './About'
import Contact from './Contact'
import { CursorContext } from '../../Cursor'

function AboutMenu({ about }) {
  const [viewing, setViewing] = useState('Hello')
  const { onCursor } = useContext(CursorContext)
  const [dimensions, setDimensions] = useState({ x: '406px', y: '402px' })

  let color
  let selectorWidth
  let content
  switch (viewing) {
    case 'Hello':
      color = '#ffbd3c'
      selectorWidth = 381
      content = <Hello data={about.Hello} />
      break
    case 'About':
      color = '#4383ff'
      selectorWidth = 236
      content = <About data={about.About} />
      break
    case 'Contact':
      color = '#ff5353'
      selectorWidth = 81
      content = <Contact data={about.Contact} />
      break
  }

  useEffect(() => {
    const x = about[viewing]?.dimensions.x
    const y = about[viewing]?.dimensions.y
    setDimensions({
      x,
      y,
    })
  }, [viewing])

  return (
    <section
      id="aboutMenu"
      style={{
        height: `${dimensions.y + 162}px`,
      }}
    >
      <div className="buttons">
        <button
          className="button"
          id="helloButton"
          onMouseEnter={() => onCursor('pointer')}
          onMouseLeave={onCursor}
          onClick={() => setViewing('Hello')}
          style={{ width: `${about.Hello.button.width}px`, backgroundColor: '#ffbd3c' }}
        >
          Hello
        </button>
        <button
          className="button"
          id="aboutButton"
          onMouseEnter={() => onCursor('pointer')}
          onMouseLeave={onCursor}
          onClick={() => setViewing('About')}
          style={{ width: `${about.About.button.width}px`, backgroundColor: '#4383ff' }}
        >
          About
        </button>
        <button
          className="button"
          id="contactButton"
          onMouseEnter={() => onCursor('pointer')}
          onMouseLeave={onCursor}
          onClick={() => setViewing('Contact')}
          style={{ width: `${about.Contact.button.width}px`, backgroundColor: '#ff5353' }}
        >
          Contact
        </button>
      </div>
      <div
        className="left-bound"
        style={{
          width: `${selectorWidth}px`,
        }}
      />
      <section
        id="content"
        style={{
          width: `${dimensions.x}px`,
          height: `${dimensions.y}px`,
        }}
      >
        {content}
      </section>
      <section
        id="content-shadow"
        style={{
          backgroundColor: `${color}`,
          width: `${dimensions.x}px`,
          height: `${dimensions.y}px`,
        }}
      />
    </section>
  )
}

export default AboutMenu
