import React, { useEffect } from 'react'
import Fade from 'react-reveal/Fade'
import DocumentWrapper from './common/DocumentWrapper'
import TitleDetailsBlurb from './common/TitleDetailsBlurb'
import { scroller } from 'react-scroll'
import ScrollSection from './common/ScrollSection'
import { scrollToViewingOptions } from '../../App'
import LargeImage from '../../GenericImage/LargeImage'
import Scroller from './common/Scroller'

function Saab(props) {
  const { data, isMobile, setBelowTop } = props

  useEffect(() => {
    scroller.scrollTo('ref1', scrollToViewingOptions)
  }, [])

  return (
    <>
      <Scroller setBelowTop={setBelowTop} />
      <DocumentWrapper isMobile={isMobile}>
        <ScrollSection id={'ref1'}>
          <TitleDetailsBlurb
            title={data.title}
            year={data.year}
            categories={data.categories}
            blurb={data.blurb}
            isMobile={isMobile}
          />
          <div style={{ height: '60px' }} />
        </ScrollSection>
        <ScrollSection id={'ref3'}>
          <div className="wideSaab">
            <LargeImage filename={data.imageOne} alt={'saab one'} />
          </div>
        </ScrollSection>
        {/* <div style={{ height: '1px' }} /> */}
        <ScrollSection id={'ref4'}>
          <div className="wideSaab">
            <LargeImage filename={data.imageTwo} alt={'saab one'} />
          </div>
        </ScrollSection>
      </DocumentWrapper>
    </>
  )
}

export default Saab
