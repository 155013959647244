import React, { useEffect } from 'react'
import DocumentWrapper from './common/DocumentWrapper'
import TitleDetailsBlurb from './common/TitleDetailsBlurb'
import { scroller } from 'react-scroll'
import ScrollSection from './common/ScrollSection'
import GenericImage from '../../GenericImage/GenericImage'
import Website from './common/Website'
import { scrollToViewingOptions } from '../../App'
import Scroller from './common/Scroller'

function SargassoDesign(props) {
  const { data, isMobile, setBelowTop } = props

  useEffect(() => {
    scroller.scrollTo('ref1', scrollToViewingOptions)
  }, [])

  return (
    <>
      <Scroller setBelowTop={setBelowTop} />

      <DocumentWrapper isMobile={isMobile}>
        <ScrollSection id={'ref1'}>
          <TitleDetailsBlurb
            title={data.title}
            year={data.year}
            categories={data.categories}
            blurb={data.blurb}
            isMobile={isMobile}
          />
          <Website url={data.url} isMobile={isMobile} />
          <div style={{ height: isMobile ? '0px' : '40px' }} />
        </ScrollSection>
        <ScrollSection id={'ref2'}>
          <div className="wideSargasso">
            <GenericImage filename={data.imageOne} alt={'sargasso one'} />
          </div>
        </ScrollSection>
      </DocumentWrapper>
    </>
  )
}

export default SargassoDesign
