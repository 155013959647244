import React from 'react'
import GenericSVG from '../../GenericImage/GenericSVG'

function ContactMobile({ data }) {
  return (
    <section id="contactMobile">
      <div id="paragraphOne">{data.paragraphOneMobile}</div>
      <a href="mailto:annechancemuller@icloud.com" target="_blank">
        <div id="email">
          <div id="emailIcon">
            <GenericSVG filename={data.icon} alt={'email icon'} />
          </div>
          <div id="instructions">{data.instructionsMobile}</div>
        </div>
      </a>
    </section>
  )
}

export default ContactMobile
