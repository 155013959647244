import React from 'react'
import GenericSVG from '../GenericImage/GenericSVG'

function Footer({ viewing }) {
  return (
    <section id="footer" style={{ marginTop: viewing ? '0px' : '-8px' }}>
      <div id="footerContent">
        <div id="footerImage">
          <a href="https://www.linkedin.com/in/anne-muller" target="_blank">
            <GenericSVG filename="footer-linked-in" alt="linked-in" />
          </a>
        </div>
        <div id="footerImage">
          <a href="http://instagram.com/upperform" target="_blank">
            <GenericSVG filename="footer-instagram" alt="linked-in" />
          </a>
        </div>
        <div id="footerImage">
          <a href="mailto:annechancemuller@icloud.com" target="_blank">
            <GenericSVG filename="footer-email" alt="linked-in" />
          </a>
        </div>
      </div>
      <div id="footerText">
        <span>{`big thanks to `}</span>
        <span>
          <a href="https://www.sargasso.design" target="_blank">
            Sargasso Design
          </a>
        </span>
        <span>{` for the code :)`}</span>
      </div>
    </section>
  )
}

export default Footer
