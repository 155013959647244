import { nanoid } from 'nanoid'

// HEAD DATA
export const headData = {
  title: 'Anne Chance Muller', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'my portfolio', // e.g: Welcome to my website
}

export const sidebarData = {
  img: 'arrow.png',
}

// ABOUT DATA
export const aboutData = {
  Hello: {
    header: 'welcome to the portfolio of',
    name: 'Anne Muller',
    subtitle: 'designer of things',
    expertise: ['graphic', 'digital', 'ui/ux'],
    spacer: 'squiggle.svg',
    button: {
      width: 126,
    },
    dimensions: {
      x: 406,
      y: 451,
    },
  },
  About: {
    paragraphOne: `I'm an artist and designer based out of Portsmouth, New Hampshire, with a BA in Fine Art from Dartmouth College.`,
    paragraphTwo: `Most likely to be found in the studio, at the beach, or biking about town with basket full of vegetables and baguettes.`,
    spacer: 'squiggle.svg',
    button: {
      width: 132,
    },
    dimensions: {
      x: 406,
      y: 402,
    },
  },
  Contact: {
    paragraphOne: `Please don't hesitate to get in touch!`,
    paragraphOneMobile: `Get in touch!`,
    icon: 'email-plane.svg',
    instructions: 'click to copy email',
    instructionsMobile: 'tap to email',
    button: {
      width: 159,
    },
    dimensions: {
      x: 406,
      y: 346,
    },
  },
}

// GRID DATA
export const gridData = [
  {
    id: nanoid(),
    coordinates: [1, 1],
    title: 'Sketchbook',
    backgroundColor: '--primary-red',
    hoverImage: 'sketchbook-square.jpg',
    coordinatesMobile: [4, 1],
    backgroundColorMobile: '--primary-yellow',
  },
  {
    id: nanoid(),
    coordinates: [2, 1],
    title: 'Sargasso Design',
    backgroundColor: '--primary-background',
    hoverImage: 'sargasso-square.png',
    coordinatesMobile: [1, 1],
    backgroundColorMobile: '--primary-red',
  },
  {
    id: nanoid(),
    coordinates: [2, 2],
    title: `PK's Four Brothers Farm`,
    backgroundColor: '--primary-background',
    hoverImage: 'pk-square.png',
    coordinatesMobile: [5, 2],
    backgroundColorMobile: '--primary-green',
  },
  {
    id: nanoid(),
    coordinates: [3, 1],
    title: 'Lister',
    backgroundColor: '--primary-background',
    hoverImage: 'lister-square.png',
    coordinatesMobile: [3, 2],
    backgroundColorMobile: '--primary-blue',
  },
  {
    id: nanoid(),
    coordinates: [3, 2],
    title: 'Line at KAF',
    backgroundColor: '--primary-background',
    hoverImage: 'line-at-kaf-square.png',
    coordinatesMobile: [3, 1],
    backgroundColorMobile: '--primary-background',
  },
  {
    id: nanoid(),
    coordinates: [3, 3],
    title: 'Upper Form',
    backgroundColor: '--primary-blue',
    hoverImage: 'upper-form-square.jpg',
    coordinatesMobile: [1, 2],
    backgroundColorMobile: '--primary-background',
  },
  {
    id: nanoid(),
    coordinates: [4, 1],
    title: 'Stationeries',
    backgroundColor: '--primary-green',
    hoverImage: 'stationeries-square.png',
    coordinatesMobile: [4, 2],
    backgroundColorMobile: '--primary-background',
  },
  {
    id: nanoid(),
    coordinates: [4, 2],
    title: 'SAAB',
    backgroundColor: '--primary-background',
    hoverImage: 'saab-square.png',
    coordinatesMobile: [2, 2],
    backgroundColorMobile: '--primary-background',
  },
  {
    id: nanoid(),
    coordinates: [4, 3],
    title: 'Double Zero',
    backgroundColor: '--primary-background',
    hoverImage: 'double-zero-square.png',
    coordinatesMobile: [5, 1],
    backgroundColorMobile: '--primary-background',
  },
  {
    id: nanoid(),
    coordinates: [4, 4],
    title: 'Paper Islands',
    backgroundColor: '--primary-yellow',
    hoverImage: 'paper-islands-square.png',
    coordinatesMobile: [2, 1],
    backgroundColorMobile: '--primary-background',
  },
]

export const projectsData = {
  Sketchbook: {
    title: 'Sketchbook',
    year: '2016',
    categories: ['pen and ink', 'watercolor'],
    blurb: `A collection of drawings & watercolors from the last six years, done mostly in/on trains, hotels, hostels, and sidewalks. Some from personal correspondence.`,
    imageOne: 'travel-sketchbook-one.png',
    captionOne: 'Looking down Ramsay Garden from Ramsay Lane, Edinburgh. October 2016.',
    imageTwo: 'travel-sketchbook-two.png',
    captionTwo: 'Ramsay Garden, Edinburgh, Scotland. October 2016.',
    imageThree: 'travel-sketchbook-three.png',
    captionThree: 'The second floor cafe at Books Upstairs, Dublin, Ireland. October 2016.',
    imageFour: 'travel-sketchbook-four.png',
    captionFour: 'Priscilla in the Botanic Gardens. Copenhagen, Denmark. October 2016.',
    imageFive: 'travel-sketchbook-five.png',
    captionFive: 'Out the windows of Pavillon des Canaux, Paris. December 2016.',
    imageSix: 'travel-sketchbook-six.png',
    captionSix: 'Rue du Chevalier de la Barre, Montmartre, Paris. December 2016.',
    imageSeven: 'travel-sketchbook-seven.png',
    captionSeven: 'Looking down Kirchgasse. Zurich, December 2016.',
    imageEight: 'travel-sketchbook-eight.png',
    captionEight: 'Le Jardin du Luxembourg, Paris. March 2018.',
    imageNine: 'travel-sketchbook-nine.png',
    captionNine: 'Climbing up Chartres Cathedral, Chartres, France. March 2018.',
    imageTen: 'travel-sketchbook-ten.png',
    captionTen: 'Lund Cathedral, Lund, Sweden. February 2019.',
    imageEleven: 'travel-sketchbook-eleven.png',
    captionEleven: 'Lund, Sweden. February 2019.',
    imageTwelve: 'travel-sketchbook-twelve.png',
    captionTwelve: 'Lund Botanical Gardens, Lund, Sweden. February 2019.',
    imageThirteen: 'travel-sketchbook-thirteen.png',
    captionThirteen: 'Tallinn, Estonia. February 2019.',
    imageFourteen: 'travel-sketchbook-fourteen.png',
    captionFourteen: 'Tallinn, Estonia. February 2019.',
  },
  Lister: {
    title: 'List.',
    year: '2020',
    categories: ['figma'],
    blurb: `A wireframing exercise for a simple list mobile app.`,
    imageOne: 'lister-one.png',
    imageTwo: 'lister-two.png',
    imageThree: 'lister-three.png',
    imageFour: 'lister-four.png',
    imageFive: 'lister-five.png',
  },
  'Sargasso Design': {
    title: 'Sargasso Design',
    year: '2021',
    categories: ['logo design', 'web design', '3D modeling'],
    blurb: `Designing the digital presence of Sargasso Design, a two-person design/engineering studio specializing in three.js web creations, with the intention of showcasing the studio’s ability to render beautiful, interactive 3D models in digital space.\n\nProject in progress; I’m currently working on initial scene setup/layout for the website’s main interactive 3D background, ‘poolside’.`,
    imageOne: 'sargasso-one.png',
    url: 'https://www.sargasso.design',
  },
  Stationeries: {
    title: 'Stationeries',
    year: '2020',
    categories: ['print'],
    blurb: 'Designs for custom stationery sets, drawn on Procreate.',
    sectionOneTitle: 'Dear little newsprint mice for my sister.',
    imageOne: 'stationeries-mouse-1.png',
    imageTwo: 'stationeries-mouse-2.png',
    imageThree: 'stationeries-mouse-3.png',
    imageFour: 'stationeries-mouse-4.png',
    sectionTwoTitle: 'Cats for Alexe, after Björn',
    imageFive: 'stationeries-cat-1.png',
    imageSix: 'stationeries-cat-2.png',
    imageSeven: 'stationeries-cat-3.png',
    imageEight: 'stationeries-cat-4.png',
  },
  'Line at KAF': {
    title: 'Line at KAF',
    year: '2018',
    categories: ['visual identity', 'web design', '3D modeling'],
    blurb: `A mobile application for Dartmouth students that displays the number of customers waiting at Baker Library’s King Arthur Flour café. The application visualizes data collected by cameras using a deep-learning algorithm to count the number of customers standing in line at the café at any given time, allowing Dartmouth students to plan their visits to the café accordingly. A project at Dartmouth’s Dali Lab.`,
    details: {
      Partners: `Cosan Lab’s Jin Cheong & Eshin Jolly`,
      Developers: `Jane Lee & Teddy Ni`,
      Designer: `Anne Muller`,
    },
    description: `Students at Dartmouth College frequent a café in Baker-Berry Library that is operated by the King Arthur Flour company, known to students as ‘KAF.’ The café is located in the center of the library and has two windows, of which only one is open during lighter-traffic times of the day. As a consequence, students often gather around the second (closed) side during anticipated busier hours, knowing that it will soon open and they might be able to snag one of the first spots in line. Often, both windows have lines in excess of 20 students. Wait times are sometimes too long fit between classes, yet many students risk it anyways - KAF is, after all, known to have the best coffee and tastiest snacks on campus.`,
    headerOne: 'Design Features',
    subHeaderOne: 'Color system.',
    subHeaderOneText: 'Familiar colors convey vital information at a glance.',
    greenText: 'GREEN: short or nonexistent line; a visit to the cafe will be very efficent.',
    yellowText: 'YELLOW: A medium length line; perfectly fine if you’ve got a few minutes to spare.',
    redText: 'RED: A long line; not the best time to visit KAF if you’re in a hurry.',
    subHeaderTwo: 'Landmarks.',
    subHeaderTwoText: 'Representing the length of each line in real space by using well-known landmarks.',
    cupText: `ORDERING WINDOW: A coffee cup represents the café ordering window - effectively, a line length of zero. The distance between this landmark and the subsequent landmarks gives the user an idea of the line length in relation to the physical space of the café.`,
    doorText: `WINDOW ONE: Students already use the door at the back of the café’s first window as a metric of line length. For example, if you’ve got class in five minutes and the KAF line is out the door, you can be certain you won’t be on time for class if you try and squeak in a coffee on the way there.`,
    windowText: `WINDOW TWO: In order for the line at the second window to fit in the library lobby, it makes a 90º turn around a signpost. This bend in the line is the only recognizable landmark of Window Two.`,
    subHeaderThree: 'Card swiping.',
    subHeaderThreeText: 'For easy navigation between the two windows.',
    subHeaderFour: 'Menu display.',
    subHeaderFourText: 'A simple interface indicating menu item availability.',
    subHeaderFive: 'Trend visualization.',
    subHeaderFiveText: 'Allowing students to plan future trips based on past trends.',
    subHeaderSix: 'Easy navigation.',
    subHeaderSixText: 'A bottom tab bar for easy access.',
    headerTwo: 'Final Screens',

    imageOne: 'kaf-one.png',
    imageTwo: 'kaf-two.png',
    imageThree: 'kaf-three.png',
    imageFour: 'kaf-four.png',
    imageFive: 'kaf-five.png',
    imageSix: 'kaf-six.png',
    imageSeven: 'kaf-seven.png',
    imageEight: 'kaf-eight.gif',
    imageNine: 'kaf-nine.png',
    imageTen: 'kaf-ten.png',
    imageEleven: 'kaf-eleven.gif',
    imageTwelve: 'kaf-twelve.png',
  },
  'Upper Form': {
    title: 'Upper Form',
    year: '2021',
    categories: ['garment design', 'visual identity', 'photography'],
    blurb: `Clothing heavily inspired by old photographs, vintage collegiate mom-and-pop style, and a hefty dose of boarding school nostalgia. Every garment is designed and sewn by me in my Portsmouth, NH studio. \n \nUpper Form is the intersection of my interests in slow fashion, sustainability, durability, hand-made goods, branding, and design.`,
    url: 'https://www.upperform.studio',
    imageOne: 'upper-form-one.png',
    imageTwo: 'upper-form-two.png',
    imageThree: 'upper-form-three.png',
    imageFour: 'upper-form-four.png',
    imageFive: 'upper-form-five.png',
    imageSix: 'upper-form-six.png',
    imageSeven: 'upper-form-seven.png',
    imageEight: 'upper-form-eight.png',
    imageNine: 'upper-form-nine.png',
    imageTen: 'upper-form-ten.png',
  },
  [`PK's Four Brothers Farm`]: {
    title: `PK's Four Brothers Farm`,
    year: '2018',
    categories: ['branding', 'print design'],
    blurb: `Product labels, pamphlets, promotional handouts, stickers, and banners with a decidedly hand-drawn feel for an organic permaculture farm in Bernardsville, New Jersey. Featuring a digitial watercolor of the farm, hand-lettering, and various illustrations.`,
    imageOne: 'pk-one.png',
    imageTwo: 'pk-two.png',
    imageThree: 'pk-three.png',
  },
  SAAB: {
    title: 'SAAB',
    year: '2021',
    categories: ['blender'],
    blurb: `An exercise in low-poly digital modeling and animtation. Featuring a particle smoke system, dramatic lighting, and the digital version of my beloved Saab :) In progress - planning on developing this model further for use in a simple three.js browser game.`,
    imageOne: 'saab-two.png',
    imageTwo: 'saab-three.png',
  },
  'Double Zero': {
    title: 'Double Zero',
    year: '2020',
    categories: ['logo', 'packaging'],
    blurb: `Concept art for a fictitious all-natural ice cream brand. An exercise in color, pattern, texture, and typeface.`,
    imageOne: 'double-zero-one.png',
    imageTwo: 'double-zero-two.png',
    imageThree: 'double-zero-three.png',
    imageFour: 'double-zero-four.png',
    imageFive: 'double-zero-five.png',
  },
  'Paper Islands': {
    title: 'Paper Islands',
    year: '2020',
    categories: ['website', 'logo', 'graphics'],
    blurb: `The beginnings of a digital pattern book of proper New England homes, based on the historic houses of Portsmouth, New Hampshire. Eventually to include structural plans and locally-sourced and sustainable material suggestions. \n \nOngoing project.`,
    imageOne: 'paper-islands-logo.png',
    imageTwo: 'paper-islands.gif',
    imageThree: 'paper-islands-zoom-one',
    imageFour: 'paper-islands-zoom-two',
    imageFive: 'paper-islands-line-one',
    imageSix: 'paper-islands-line-two',
    imageSeven: 'paper-islands-line-three',
  },
}
