import React, { useEffect } from 'react'
import Fade from 'react-reveal/Fade'
import DocumentWrapper from './common/DocumentWrapper'
import TitleDetailsBlurb from './common/TitleDetailsBlurb'
import { scroller } from 'react-scroll'
import ScrollSection from './common/ScrollSection'
import Scroller from './common/Scroller'
import Website from './common/Website'
import LargeImage from '../../GenericImage/LargeImage'
import GenericImage from '../../GenericImage/GenericImage'
import { scrollToViewingOptions } from '../../App'

function UpperForm(props) {
  const { data, belowTop, setBelowTop, isMobile } = props

  useEffect(() => {
    scroller.scrollTo('ref1', scrollToViewingOptions)
  }, [])

  return (
    <>
      <Scroller setBelowTop={setBelowTop} />
      <DocumentWrapper isMobile={isMobile}>
        <ScrollSection id={'ref1'}>
          <TitleDetailsBlurb
            title={data.title}
            year={data.year}
            categories={data.categories}
            blurb={data.blurb}
            isMobile={isMobile}
          />
          <Website url={data.url} isMobile={isMobile} />
          <div style={{ height: isMobile ? '0px' : '40px' }} />
        </ScrollSection>

        <ScrollSection id={'ref2'}>
          <div className="wideUpperForm" style={{ backgroundColor: '#e795a529' }}>
            <div id="upper-form-image-one">
              <GenericImage filename={data.imageOne} alt={'upper form one'} />
            </div>
          </div>
          <div style={{ height: isMobile ? '40px' : '0px' }} />
        </ScrollSection>

        {isMobile ? (
          <ScrollSection id={'ref3'}>
            <div className="wideUpperFormMobile" style={{ backgroundColor: '#e795a529' }}>
              <div id="upper-form-image-one">
                <GenericImage filename={data.imageTwo} alt={'upper form one'} />
              </div>
            </div>
            <div className="wideUpperFormMobile" style={{ backgroundColor: '#e795a529' }}>
              <div id="upper-form-image-one">
                <GenericImage filename={data.imageThree} alt={'upper form one'} />
              </div>
            </div>
            <div className="wideUpperFormMobile" style={{ backgroundColor: '#e795a529' }}>
              <div id="upper-form-image-one">
                <GenericImage filename={data.imageFour} alt={'upper form one'} />
              </div>
            </div>
            <div className="wideUpperFormMobile" style={{ backgroundColor: '#e795a529' }}>
              <div id="upper-form-image-mobile">
                <GenericImage filename={data.imageFive} alt={'upper form one'} />
              </div>
            </div>
            <div style={{ height: isMobile ? '40px' : '0px' }} />
          </ScrollSection>
        ) : (
          <ScrollSection id={'ref3'}>
            <div className="narrowUpperForm">
              <div className="upperFormRow">
                <div className="left">
                  <GenericImage filename={data.imageTwo} alt={'upper form two'} />
                </div>
                <div className="right">
                  <GenericImage filename={data.imageThree} alt={'upper form three'} />
                </div>
              </div>
              <div className="upperFormRow">
                <div className="left">
                  <GenericImage filename={data.imageFour} alt={'upper form four'} />
                </div>
                <div className="right">
                  <GenericImage filename={data.imageFive} alt={'upper form five'} />
                </div>
              </div>
            </div>
          </ScrollSection>
        )}

        <ScrollSection id={'ref4'}>
          <div className="wideUpperForm" style={{ backgroundColor: '#B2C4D326' }}>
            <div id="upper-form-image-one">
              <GenericImage filename={data.imageSix} alt={'upper form one'} />
            </div>
          </div>
        </ScrollSection>

        <ScrollSection id={'ref5'}>
          <div className={isMobile ? 'wideUpperFormMobile' : 'narrowUpperForm'}>
            <div style={{ height: isMobile ? '40px' : '180px' }} />
            <LargeImage filename={data.imageSeven} alt={'upper form seven'} />
            <div style={{ height: isMobile ? '40px' : '120px' }} />
            <LargeImage filename={data.imageEight} alt={'upper form eight'} />
            <div style={{ height: isMobile ? '40px' : '120px' }} />
            <LargeImage filename={data.imageNine} alt={'upper form nine'} />
          </div>
        </ScrollSection>

        <div style={{ height: isMobile ? '60px' : '180px' }} />
        <div className="wideUpperForm" style={{ backgroundColor: '#F3F3F4' }}>
          <div id="upper-form-image-one">
            <GenericImage filename={data.imageTen} alt={'upper form ten'} />
          </div>
        </div>
      </DocumentWrapper>
    </>
  )
}

export default UpperForm
