import React, { useState, useEffect, useContext } from 'react'
import { PortfolioProvider } from '../context/context'
import Fade from 'react-reveal/Fade'
import { aboutData, gridData, projectsData } from '../data/data'
import Grid from './Grid/Grid'
import AboutMenu from './AboutMenu/Desktop/AboutMenu'
import GenericSVG from './GenericImage/GenericSVG'
import Viewing from './Viewing/Viewing'
import { scroller, Element } from 'react-scroll'
import ScrollSection from './Viewing/Projects/common/ScrollSection'
import CursorProvider from './Cursor'
import useWindowSize from '../hooks/useWindowResize'
import AboutMenuMobile from './AboutMenu/Mobile/AboutMenuMobile'
import MobileApp from './MobileApp/MobileApp'
import FooterMobile from './Footer/FooterMobile'
import Footer from './Footer/Footer'

export const scrollToViewingOptions = {
  duration: 750,
  delay: 0,
  smooth: true,
  // offset: -7,
  offset: 0,
}

function App() {
  const [isDesktop, setIsDesktop] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const size = useWindowSize()

  const [viewing, setViewing] = useState(null)

  const [about, setAbout] = useState(null)
  const [projects, setProjects] = useState({})
  const [squaresData, setSquaresData] = useState([])

  useEffect(() => {
    setAbout({ ...aboutData })
    setProjects({ ...projectsData })
    setSquaresData([...gridData])
  }, [])

  function onClick(title) {
    if (isMobile) {
      setViewing(title)
    } else {
      if (title === viewing) {
        onReturnToTop()
      } else {
        setViewing(title)
      }
    }
  }

  function onReturnToTop() {
    scroller.scrollTo('ref0', {
      duration: 750,
      delay: 50,
      smooth: true,
      offset: 0,
    })

    setTimeout(() => setViewing(''), 1000)
  }

  useEffect(() => {
    if (window.innerWidth > 950) {
      setIsDesktop(true)
      setIsMobile(false)
    } else {
      setIsMobile(true)
      setIsDesktop(false)
    }
  }, [size])

  return (
    // <CursorProvider>
    <PortfolioProvider value={{ about, projects }}>
      {isDesktop && (
        <>
          <div
            style={{
              height: 'max-content',
              width: '100vw',
              position: 'relative',
              top: 0,
              left: 0,
              border: '8px solid black',
              boxSizing: 'border-box',
            }}
          >
            <ScrollSection id={'ref0'}>
              {about && <AboutMenu about={about} />}
              {squaresData && <Grid viewing={viewing} squaresData={squaresData} onClick={onClick} />}
            </ScrollSection>
            {viewing && <Viewing viewing={viewing} onReturnToTop={onReturnToTop} projects={projects} />}
            <Footer viewing={viewing} />
          </div>
        </>
      )}
      {isMobile && (
        <>
          <div
            style={{
              height: 'max-content',
              minWidth: '294px',
              position: 'relative',
              top: 0,
              boxSizing: 'border-box',
            }}
          >
            {about && squaresData && projects && (
              <MobileApp
                about={about}
                projects={projects}
                squaresData={squaresData}
                viewing={viewing}
                onClick={onClick}
                onReturnToTop={onReturnToTop}
              />
            )}
          </div>
        </>
      )}
    </PortfolioProvider>
    // </CursorProvider>
  )
}

export default App
