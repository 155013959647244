import React from 'react'
import GenericSVG from '../../GenericImage/GenericSVG'

function HelloMobile({ data }) {
  return data ? (
    <section id="helloMobile">
      <div id="header">{data.header}</div>
      <div id="helloSpacer">
        <GenericSVG filename={data.spacer} alt={'spacer'} />
      </div>
      <div id="name">{data.name}</div>
      <div id="subtitle">{data.subtitle}</div>
      <div id="helloSpacer">
        <GenericSVG filename={data.spacer} alt={'spacer'} />
      </div>
      <div id="expertise">
        {data.expertise.map((item) => (
          <div className="expertiseItem" key={item}>
            {item}
          </div>
        ))}
      </div>
    </section>
  ) : (
    <></>
  )
}

export default HelloMobile
