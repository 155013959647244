import React, { useEffect } from 'react'
import Fade from 'react-reveal/Fade'
import DocumentWrapper from './common/DocumentWrapper'
import TitleDetailsBlurb from './common/TitleDetailsBlurb'
import { scroller } from 'react-scroll'
import ScrollSection from './common/ScrollSection'
import { scrollToViewingOptions } from '../../App'
import GenericImage from '../../GenericImage/GenericImage'
import Scroller from './common/Scroller'

function PKs(props) {
  const { data, isMobile, setBelowTop } = props

  useEffect(() => {
    scroller.scrollTo('ref1', scrollToViewingOptions)
  }, [])

  return (
    <>
      <Scroller setBelowTop={setBelowTop} />
      <DocumentWrapper isMobile={isMobile}>
        <ScrollSection id={'ref1'}>
          <TitleDetailsBlurb
            title={data.title}
            year={data.year}
            categories={data.categories}
            blurb={data.blurb}
            isMobile={isMobile}
          />
          <div style={{ height: '60px' }} />
        </ScrollSection>
        <ScrollSection id={'ref3'}>
          <div className={isMobile ? 'widePKMobile' : 'widePK'}>
            <GenericImage filename={data.imageOne} alt={'pks one'} />
          </div>
        </ScrollSection>
        {/* <div style={{ height: '1px' }} /> */}
        <ScrollSection id={'ref4'}>
          <div className={isMobile ? 'widePKMobile' : 'widePK'}>
            <GenericImage filename={data.imageTwo} alt={'pks two'} />
          </div>
        </ScrollSection>
        <ScrollSection id={'ref4'}>
          <div className={isMobile ? 'widePKMobile' : 'widePK'}>
            <GenericImage filename={data.imageThree} alt={'pks three'} />
          </div>
        </ScrollSection>
      </DocumentWrapper>
    </>
  )
}

export default PKs
