import React, { useEffect } from 'react'
import Fade from 'react-reveal/Fade'
import GenericImage from '../../GenericImage/GenericImage'
import GenericSVG from '../../GenericImage/GenericSVG'
import DocumentWrapper from './common/DocumentWrapper'
import TitleDetailsBlurb from './common/TitleDetailsBlurb'
import { scroller, Element } from 'react-scroll'
import Scroller from './common/Scroller'
import ScrollSection from './common/ScrollSection'
import { scrollToViewingOptions } from '../../App'
import GenericGIF from '../../GenericImage/GenericGIF'

function PaperIslands(props) {
  const { data, setBelowTop, isMobile } = props

  useEffect(() => {
    scroller.scrollTo('ref1', scrollToViewingOptions)
  }, [])

  return (
    <>
      <Scroller setBelowTop={setBelowTop} />
      <DocumentWrapper isMobile={isMobile}>
        <ScrollSection id={'ref1'}>
          <TitleDetailsBlurb
            title={data.title}
            year={data.year}
            categories={data.categories}
            blurb={data.blurb}
            isMobile={isMobile}
          />
        </ScrollSection>

        <section id={isMobile ? 'paper-islands-mobile' : 'paper-islands'}>
          <ScrollSection id={'ref2'}>
            <div className={isMobile ? 'logoMobile' : 'logo'}>
              <GenericImage filename={data.imageOne} alt={'paper islands'} />
            </div>
            <div style={{ height: '40px' }} />
            <div className="wide">
              <GenericGIF filename={data.imageTwo} alt={'paper islands'} />
            </div>
          </ScrollSection>

          <ScrollSection id={'ref3'}>
            <div style={{ height: '60px' }} />
            <div className="wide">
              <GenericImage filename={data.imageThree} alt={'paper islands'} />
            </div>
            <div style={{ height: '60px' }} />
            <div className="wide">
              <GenericImage filename={data.imageFour} alt={'paper islands'} />
            </div>
          </ScrollSection>

          <ScrollSection id={'ref4'}>
            <div style={{ height: '60px' }} />
            <div className="wide">
              <GenericImage filename={data.imageFive} alt={'paper islands'} />
            </div>
            <div style={{ height: '60px' }} />
            <div className="wide">
              <GenericImage filename={data.imageSix} alt={'paper islands'} />
            </div>
            <div style={{ height: '60px' }} />
            <div className="wide">
              <GenericImage filename={data.imageSeven} alt={'paper islands'} />
            </div>
          </ScrollSection>

          <ScrollSection id={'ref5'}>
            <div style={{ height: isMobile ? '40px' : '273px' }} />
            <div className={isMobile ? 'logoMobile' : 'logo'}>
              <GenericImage filename={data.imageOne} alt={'paper islands'} />
            </div>
            <div style={{ height: isMobile ? '40px' : '309px' }} />
          </ScrollSection>
        </section>
      </DocumentWrapper>
    </>
  )
}

export default PaperIslands
