import React, { useEffect } from 'react'
import DocumentWrapper from './common/DocumentWrapper'
import TitleDetailsBlurb from './common/TitleDetailsBlurb'
import { scroller } from 'react-scroll'
import ScrollSection from './common/ScrollSection'
import { scrollToViewingOptions } from '../../App'
import GenericImage from '../../GenericImage/GenericImage'
import Scroller from './common/Scroller'

function Sketchbook(props) {
  const { data, isMobile, setBelowTop } = props

  useEffect(() => {
    scroller.scrollTo('ref1', scrollToViewingOptions)
  }, [])

  const images = [
    <>
      <GenericImage filename={data.imageOne} alt={''} />
      <span className="sketchbookCaption">{data.captionOne}</span>
    </>,
    <>
      <GenericImage filename={data.imageThree} alt={''} />
      <span className="sketchbookCaption">{data.captionThree}</span>
    </>,
    <>
      <GenericImage filename={data.imageFive} alt={''} />
      <span className="sketchbookCaption">{data.captionFive}</span>
    </>,
    <>
      <GenericImage filename={data.imageSeven} alt={''} />
      <span className="sketchbookCaption">{data.captionSeven}</span>
    </>,
    <>
      <GenericImage filename={data.imageNine} alt={''} />
      <span className="sketchbookCaption">{data.captionNine}</span>
    </>,
    <>
      <GenericImage filename={data.imageEleven} alt={''} />
      <span className="sketchbookCaption">{data.captionEleven}</span>
    </>,
    <>
      <GenericImage filename={data.imageTwo} alt={''} />
      <span className="sketchbookCaption">{data.captionTwo}</span>
    </>,
    <>
      <GenericImage filename={data.imageFour} alt={''} />
      <span className="sketchbookCaption">{data.captionFour}</span>
    </>,
    <>
      <GenericImage filename={data.imageSix} alt={''} />
      <span className="sketchbookCaption">{data.captionSix}</span>
    </>,
    <>
      <GenericImage filename={data.imageEight} alt={''} />
      <span className="sketchbookCaption">{data.captionEight}</span>
    </>,
    <>
      <GenericImage filename={data.imageTen} alt={''} />
      <span className="sketchbookCaption">{data.captionTen}</span>
    </>,
    <>
      <GenericImage filename={data.imageTwelve} alt={''} />
      <span className="sketchbookCaption">{data.captionTwelve}</span>
    </>,
    <>
      <GenericImage filename={data.imageThirteen} alt={''} />
      <span className="sketchbookCaption">{data.captionThirteen}</span>
    </>,
    <>
      <GenericImage filename={data.imageFourteen} alt={''} />
      <span className="sketchbookCaption">{data.captionFourteen}</span>
    </>,
  ]

  const randomized = images.sort(() => (Math.random() > 0.5 ? 1 : -1))
  const [column1, column2, column3] = [randomized.slice(0, 4), randomized.slice(4, 9), randomized.slice(9)]

  return (
    <>
      <Scroller setBelowTop={setBelowTop} />
      <DocumentWrapper isMobile={isMobile}>
        <ScrollSection id={'ref1'}>
          <TitleDetailsBlurb
            title={data.title}
            year={data.year}
            categories={data.categories}
            blurb={data.blurb}
            isMobile={isMobile}
          />
          <div style={{ height: isMobile ? '0px' : '40px' }} />
        </ScrollSection>
        <ScrollSection id={'ref2'}>
          <div id={isMobile ? 'sketchbookColumnsMobile' : 'sketchbookColumns'}>
            <div className={'sketchbookColumn'}>{column1}</div>
            <div className={'sketchbookColumn'}>{column2}</div>
            <div className={'sketchbookColumn'}>{column3}</div>
          </div>
          <div style={{ height: isMobile ? '0px' : '40px' }} />
        </ScrollSection>
      </DocumentWrapper>
    </>
  )
}

export default Sketchbook
