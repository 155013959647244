import React, { useEffect } from 'react'
import Fade from 'react-reveal/Fade'
import LargeImage from '../../GenericImage/LargeImage'
import DocumentWrapper from './common/DocumentWrapper'
import TitleDetailsBlurb from './common/TitleDetailsBlurb'
import { scroller, Element } from 'react-scroll'
import ScrollSection from './common/ScrollSection'
import { scrollToViewingOptions } from '../../App'
import Scroller from './common/Scroller'

function Lister(props) {
  const { data, isMobile, setBelowTop } = props

  useEffect(() => {
    scroller.scrollTo('ref1', scrollToViewingOptions)
  }, [])

  return (
    <>
      <Scroller setBelowTop={setBelowTop} />
      <DocumentWrapper isMobile={isMobile}>
        <ScrollSection id={'ref1'}>
          {/* <TitleDetailsBlurb
            title={'List.'}
            year={data.year}
            categories={data.categories}
            blurb={data.blurb}
            isMobile={isMobile}
          />
        <div style={{ height: '60px' }} /> */}
          <section id="lister">
            <LargeImage filename={data.imageOne} alt={'list one'} />
            <LargeImage filename={data.imageTwo} alt={'list two'} />
            <LargeImage filename={data.imageThree} alt={'list three'} />
            <LargeImage filename={data.imageFour} alt={'list four'} />
            <LargeImage filename={data.imageFive} alt={'list five'} />
          </section>
        </ScrollSection>
      </DocumentWrapper>
    </>
  )
}

export default Lister
