import React, { useState } from 'react'
import Fade from 'react-reveal/Fade'
import AboutMenuMobile from '../AboutMenu/Mobile/AboutMenuMobile'
import FooterMobile from '../Footer/FooterMobile'
import GridMobile from '../GridMobile/GridMobile'
import ScrollSection from '../Viewing/Projects/common/ScrollSection'
import Viewing from '../Viewing/Viewing'

function MobileApp({ about, projects, squaresData, viewing, onClick, onReturnToTop }) {
  const [menuSelection, setMenuSelection] = useState(!!viewing ? 'Work' : 'Hello')

  return (
    <section id="mobileMenu">
      <section>
        <div style={{ width: 'calc(100vw - 64px)', margin: '0 32px', height: 'max-content' }}>
          <div id="mobileMenuButtons">
            <button
              id="helloButtonMobile"
              onClick={() => {
                setMenuSelection('Hello')
                onClick('')
              }}
              style={{ backgroundColor: menuSelection === 'Hello' ? '#ffbd3c' : '#f4f4f3' }}
            >
              Hello
            </button>
            <button
              id="workButtonMobile"
              onClick={() => {
                setMenuSelection('Work')
                onClick('')
              }}
              style={{ backgroundColor: menuSelection === 'Work' ? '#ffbd3c' : '#f4f4f3' }}
            >
              Work
            </button>
          </div>
        </div>
      </section>

      {menuSelection === 'Hello' && (
        <>
          <AboutMenuMobile about={about} />
          <FooterMobile />
        </>
      )}
      {menuSelection === 'Work' && (
        <>
          <Fade bottom={true} duration={500} delay={100} distance="300px">
            <ScrollSection id={'ref0'}>
              <GridMobile squaresData={squaresData} viewing={viewing} onClick={onClick} />
            </ScrollSection>
          </Fade>
          <>
            {viewing && <Viewing viewing={viewing} onReturnToTop={onReturnToTop} projects={projects} isMobile={true} />}
          </>
          <Fade bottom={true} duration={500} delay={100} distance="50px">
            <FooterMobile viewing={viewing} />
          </Fade>
        </>
      )}
    </section>
  )
}

export default MobileApp
