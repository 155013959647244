import React from 'react'
import GenericSVG from '../../GenericImage/GenericSVG'

function About({ data }) {
  return (
    <section id="about">
      <div id="paragraphOne">{data.paragraphOne}</div>
      <div id="spacer">
        <GenericSVG filename={data.spacer} alt={'spacer'} />
      </div>
      <div id="paragraphTwo">{data.paragraphTwo}</div>
    </section>
  )
}

export default About
